
import './App.css';

import SignupUpdated from './components/signup-updated'
import Basic from './components/form-steps/Basic';
import LoginUpdated from './components/Login-updated'
import Home from './components/home';
// import BasicSales from './components/form-steps-Sales/Basic';
// import DynamicBackground from './components/Dynamicbg';
// import bf from './images/'
import {
  Routes,
  Route,
  Navigate,
  useParams,

} from "react-router-dom";
import { useEffect, useState } from 'react';
// import IMI_data from './data/imi';
// import SP_Jain_data from './data/spJain';
// import BasicDS from './components/form-steps-Sales/Basic';

function App() {

 
  return (
    <div  className="App">
     
    <Routes>
    {/* <Route path='/' element={<Home />} /> */}
    <Route path='/' element={<Navigate to="/signup/iimv" replace/>} />
    <Route path='/signup/:id' element={<SignupUpdated />} />
    {/* <Route path='/login' element={<Login />} /> */}
    <Route path='/login/:id' element={<LoginUpdated />} />
    <Route path='/form/:id' element={<Basic />} /> 
    </Routes>
    </div>
  );
}

export default App;
