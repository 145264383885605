import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  Container,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CardMedia,
  Step,
} from "@mui/material";
// import axios from "axios";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import Navbar from "../Navbar";
import { styled } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Personal from "./Personal";
import ApplicationFee from "./Application-fee";
import AdmissionFee from "./Admission-fee";
// import Logo from "../images/image 37.png";
// import Logo2 from "../images/image 32.png";
// import Logo3 from "../images/image 40.png";
// import Logo4 from "../images/image 42.png";
// import Logo5 from "../images/image 33.png";
// import Logo6 from "../images/image 35.png";
import { Check } from "@mui/icons-material";
import Education from "./Education-details";
import Payment_data from "../../data/payment_data";
import Offer from "./Offer";
import Swal from "sweetalert2";
// import Test from "./Test";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
const Basic = () => {
  let navigate = useNavigate();
  const {id}=useParams()
  const [user, setUser] = useState("");
  let [activeStep, setActiveStep] = useState(0);
  // const [steps, setSteps] = useState([]);
  const [nxt, setNxt] = useState(true);
  const [completed, setCompleted] = useState({});
  const [sheeturl,setSheetUrl]=useState("")
  const [amount,setAmount]=useState("")
  const [stepData,setStepData]=useState("")
  const [display,setDisplay]=useState(false)
  // const [id,setid]=useState("iimv")

  useEffect(() => {
    var user_id = localStorage.getItem("user_id");
    setUser(user_id);
  }, []);

 
  // console.log(id,"llllllll")
useEffect(()=>{
  // const url = new URL(window.location.href);
  // const subdomain=url.hostname.split('.')[0]
  // setid("iimv")
  // setid(url.hostname.split('.')[0]);
 
  if (localStorage.getItem("user_id")) {
    if(localStorage.getItem("university")==id){
    
      import(`../../data/${id}_data.js`)
      .then((module) => {
        const data = module.default;
        setStepData(data[0])
        // setSteps(data[0].steps)
        data[0].sheetUrl.map((val)=>{
          if(val.category==localStorage.getItem("category")){
            setSheetUrl(val.url)
          }
        })
        data[0].registration.map((val)=>{
          if(val.category==localStorage.getItem("category")){
            setAmount(val.amount)
          }
        })
      })
      .catch((error) => {
        console.error(`Error loading property file: ${error}`);
      });
     
      // setUrl(ans[0].bg)
    }
    else{
      setDisplay(true)
      Swal.fire({
        title: "Warning",
        text: "The admission portal has not been set up on your account. Please get in touch with the admission counselor.",
        icon: "warning",
        confirmButtonText: "OK",
      }).then((result)=>{
        if (result.isConfirmed) {
         
          navigate(`/Login/${localStorage.getItem("university")}`);
          localStorage.clear();
        }
      });
    }
  }
  else{
    setDisplay(true)
    Swal.fire({
      title: "Warning",
      text: "Please Re-Login",
      icon: "warning",
      confirmButtonText: "OK",
    }).then((result)=>{
      if (result.isConfirmed) {
       
        navigate(`/Login/${localStorage.getItem("university")}`);
       
      }
    });
  }
 
},[id])
const steps = [
  {
    title: "Basic Details",
    link: '../images/image 37.png',
  },
  {
    title: "Application Fee",
    link: '../images/image 32.png',
  },
  // {
  //   title: "Assessment",
  //   link: Logo3,
  // },
  {
    title: "Admission Fee",
    link: '../images/image 40.png',
  },

  {
    title: "Education and Work",
    link: '../images/image 42.png',
  },
  {
    title: "Confirmation letter",
    link: '../images/image 33.png',
  },
];
  const current_step_count=localStorage.getItem("currentStep")
  const current_step_status=localStorage.getItem("currentStepStatus")
  function stepCount() {
    
    if (current_step_count) {
      // console.log("inside step");
      if (current_step_count=='r/+KNaP4RD0DybChQ+ORJA==' && current_step_status == "pending") {
        setActiveStep(0);
        _renderStepContent(0);
      }
      if (
        ((current_step_count=='uGugRq191xjUiMV0Z4gbZQ==' &&current_step_status == "pending") || (current_step_count=='uGugRq191xjUiMV0Z4gbZQ==' && current_step_status == "inprocess") || (current_step_count=='uGugRq191xjUiMV0Z4gbZQ==' && current_step_status == "payment_inprocess")) 
        // current_step_count=='1'&& current_step_status == "complete"
      ) {
        setActiveStep(1);
        const newCompleted = completed;
        newCompleted[0] = true;
        setCompleted(newCompleted);
        _renderStepContent(1);
      }
      if (
        ((current_step_count=='UXxd22qvQ9kHfw0FjJnXaQ==' &&current_step_status == "pending") || (current_step_count=='UXxd22qvQ9kHfw0FjJnXaQ==' && current_step_status == "inprocess") || (current_step_count=='UXxd22qvQ9kHfw0FjJnXaQ==' && current_step_status == "payment_inprocess")) 
        // current_step_count=='2'&& current_step_status == "complete"
      ) {
        setActiveStep(2);
        const newCompleted = completed;
        newCompleted[0] = true;
        newCompleted[1] = true;
        setCompleted(newCompleted);
        _renderStepContent(2);
      }
      if (
        ((current_step_count=='kEZPY1a8e83FuRRLDy8czA==' &&current_step_status == "pending") || (current_step_count=='kEZPY1a8e83FuRRLDy8czA==' && current_step_status == "inprocess"))
        // current_step_count=='3'&& current_step_status == "complete"
      ) {
        setActiveStep(3);
        const newCompleted = completed;
        newCompleted[0] = true;
        newCompleted[1] = true;
        newCompleted[2] = true;
        setCompleted(newCompleted);
        _renderStepContent(3);
      }
      if (
        ((current_step_count=='VftoxbQ3nU9eJFNpAidRig==' &&current_step_status == "pending") || (current_step_count=='VftoxbQ3nU9eJFNpAidRig==' && current_step_status == "inprocess")) 
        // current_step_count=='4'&& current_step_status == "complete"
      ) {
        setActiveStep(4);
        const newCompleted = completed;
        newCompleted[0] = true;
        newCompleted[1] = true;
        newCompleted[2] = true;
        newCompleted[3] = true;
        setCompleted(newCompleted);
        _renderStepContent(4);
      }
      if (
        
        (current_step_count=='VftoxbQ3nU9eJFNpAidRig==' &&current_step_status == "complete")
      ) {
        setActiveStep(4);
        const newCompleted = completed;
        newCompleted[0] = true;
        newCompleted[1] = true;
        newCompleted[2] = true;
        newCompleted[3] = true;
        setCompleted(newCompleted);
        _renderStepContent(4);
      }
    }
  }
  useEffect(() => {
    stepCount();
    // userd();
  }, [activeStep, steps,current_step_count,current_step_status]);
 
  const handleNext1 = () => {
    // activeStep++;
   

    setActiveStep(1);
    const newCompleted = completed;
    newCompleted[0] = true;
    setCompleted(newCompleted);
    _renderStepContent(1);
    // _renderStepContent(1);
  };
  const handleNext2 = () => {
    // activeStep++;
    setActiveStep(2);
    const newCompleted = completed;
    newCompleted[0] = true;
    newCompleted[1] = true;
    setCompleted(newCompleted);
    _renderStepContent(2);
    // _renderStepContent(1);
  };
  const handleNext3 = () => {
    // activeStep++;
    setActiveStep(3);
    const newCompleted = completed;
    newCompleted[0] = true;
    newCompleted[1] = true;
    newCompleted[2] = true;
    setCompleted(newCompleted);
    _renderStepContent(3);
    // _renderStepContent(1);
  };
  const handleNext4 = () => {
    // activeStep++;
    setActiveStep(4);
    const newCompleted = completed;
    newCompleted[0] = true;
    newCompleted[1] = true;
    newCompleted[2] = true;
    newCompleted[3] = true;
    setCompleted(newCompleted);
    _renderStepContent(4);
    // _renderStepContent(1);
  };
  const handleNext5 = () => {
    // activeStep++;
    setActiveStep(5);
    const newCompleted = completed;
    newCompleted[0] = true;
    newCompleted[1] = true;
    newCompleted[2] = true;
    newCompleted[3] = true;
    newCompleted[4] = true;
    setCompleted(newCompleted);
    _renderStepContent(5);
    // _renderStepContent(1);
  };
  const handleNext6 = () => {
    // activeStep++;
    setActiveStep(6);
    const newCompleted = completed;
    newCompleted[0] = true;
    newCompleted[1] = true;
    newCompleted[2] = true;
    newCompleted[3] = true;
    newCompleted[4] = true;
    newCompleted[5] = true;
    setCompleted(newCompleted);
    // _renderStepContent(6);
    // _renderStepContent(1);
  };
  const handleBack1=()=>{
    setActiveStep(0);
    const newCompleted = completed;
    newCompleted[0] = false;
    setCompleted(newCompleted);
    _renderStepContent(0);
  }
  const handleBack2=()=>{
    setActiveStep(1);
    const newCompleted = completed;
    newCompleted[0] = true;
    newCompleted[1] = false;
    setCompleted(newCompleted);
    _renderStepContent(1);
  }
  const handleBack3=()=>{
    setActiveStep(2);
    const newCompleted = completed;
    newCompleted[0] = true;
    newCompleted[1] = true;
    newCompleted[2] = false;
    setCompleted(newCompleted);
    _renderStepContent(2);
  }
  const handleBack4=()=>{
    setActiveStep(3);
    const newCompleted = completed;
    newCompleted[0] = true;
    newCompleted[1] = true;
    newCompleted[2] = true;
    newCompleted[3] = false;
    setCompleted(newCompleted);
    _renderStepContent(3);
  }

  function _renderStepContent(steps) {
    switch (steps) {
      case 0:
        return (
          <Personal
            handleNext={handleNext1}
            setNxt={setNxt}
            // handleNext1={handleNext1}
            stepCount={stepCount}
            // userd={userd}
            step={steps}
            // firstname={firstname}
            // lastname={lastname}
          />
        );
      case 1:
        return (
          <ApplicationFee
          handleBack={handleBack1}
            // handleNext={handleNext2}
            // step={step}
            // userd={userd}
            reg_amount={amount}
            stepCount={stepCount}
            handleNext2={handleNext2}
            handleNext3={handleNext3}
          />
        );
        case 2:
          return (
            <AdmissionFee
            handleNext={handleNext3}
            handleBack={handleBack2}
            // step={step}
            stepCount={stepCount}
            key={stepData.razorpay}
            // userd={userd}
            // handleNext3={handleNext3}
          />
       
          );
      case 3:
        return (
          <Education
          handleNext={handleNext4}
          handleBack={handleBack3}
          // step={step}
          stepCount={stepCount}
          // userd={userd}
          sheetURL={sheeturl}
          policy={stepData.admissionPolicy}
          generateApi={stepData.generate_api}
          setNxt={setNxt}
            
          />
        );
      case 4:
        return <Offer handleNext={handleNext5} handleBack={handleBack4} text={stepData.confirmationText} />;
      default:
        return <div>Not Found</div>;
    }
  }

  
  const link = ['../images/image 37.png', '../images/image 32.png', '../images/image 40.png', '../images/image 42.png', '../images/image 33.png'];
  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
  
    alignItems: "center",
    // justifyContent:"center",
    marginRight:{lg:"45px",xl:"60px"},
    ...(ownerState.active && {
      color: "#002E5C",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#784af4",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  }));

  function QontoStepIcon(props) {
    const { active, completed, className, icon } = props;
    console.log(props, "deeeeeee");

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <CheckCircleIcon sx={{ color: "green", fontSize: "50px" }} />
        ) : (
          // <img src={link[icon - 1]} width={50} height={50} />
          <CardMedia 
          component="img"
          image={link[icon - 1]}
          sx={{
            width:"50px",
           height:"50px"
          }}
          />
          // <div></div>
        )}
        {/* {active?(
        document.getElementById("current").style.background="#002E5C"
        // document.getElementById("current").style.color="#fff"
       ):(
        document.getElementById("current").style.background="#2A7BCC"
       )} */}
        {/* <img src={i.link} width={50} height={40} alt="img" /> */}
      </QontoStepIconRoot>
    );
  }


  // mobile version

  const QontoStepIconRootMob = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 18,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#002E5C",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#784af4",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  }));

  function QontoStepIconMob(props) {
    const { active, completed, className, icon } = props;
    // console.log(props, "deeeeeee");

    return (
      <QontoStepIconRootMob ownerState={{ active }} className={className}>
        {completed ? (
          <CheckCircleIcon sx={{ color: "green", fontSize: "30px" }} />
        ) : (
          <img src={link[icon - 1]} width={25} height={20}  style={{marginLeft:"10px"}}/>
          // <div></div>
        )}
        {/* {active?(
        document.getElementById("current").style.background="#002E5C"
        // document.getElementById("current").style.color="#fff"
       ):(
        document.getElementById("current").style.background="#2A7BCC"
       )} */}
        {/* <img src={i.link} width={50} height={40} alt="img" /> */}
      </QontoStepIconRootMob>
    );
  }
  
  return display?(<Box></Box>): (
    <>
      <Navbar />

      <Box
        sx={{
          width: "100%",
          pt: 4,
          // backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${stepData.bg})`,
          backgroundImage: `url(${stepData.bg})`,
          backgroundSize: "cover",
          backgroundAttachment:"fixed",
          height:{lg:"100vh",xs:"89vh",sm:"90vh",md:"90vh"}
       
        }}
      >
        {/* desktop version */}
        <Box
          sx={{
            width: "82%",

            mb: 2,

            marginLeft: "auto",
            marginRight: "auto",
            display: { xs: "none", lg: "block" },
          }}
        >
          <Stepper
            nonLinear
            activeStep={activeStep}
            alternativeLabel
            connector={false}
            sx={{ml:6}}
          >
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                  <Box
                    id="current"
                    sx={{
                      background: `${completed[index] ? "#002E5C" : "#2A7BCC"}`,
                      borderRadius: "8px",
                      pt: 0.5,
                      pb: 0.5,
                      width:"146px",
                    }}
                  >
                    <Typography
                      id="currentt"
                      sx={{
                        color: "#fff",
                        fontSize: "0.8rem",
                        fontWeight: "bold",
                      }}
                    >
                      {label.title}
                    </Typography>
                  </Box>
                  </Box>
                 
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          <div>
            {/* {allStepsCompleted() ? (
                <React.Fragment></React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )} */}
          </div>
        </Box>
        <Box sx={{display: { xs: "none", lg: "block" }}}>
          {/* <Personal/> */}
          {/* <ApplicationFee/> */}
          {/* <Education/> */}
          {/* <Offer/> */}
          {_renderStepContent(activeStep)}
          {/* <AdmissionFee/> */}
          {/* <Test/> */}
        </Box>





        {/* mobile version */}

        <Box
          sx={{
            width: "100%",

            mb: 2,

            marginLeft: "auto",
            marginRight: "auto",
            display: { xs: "block", lg: "none" },
          }}
        >
          <Stepper
            nonLinear
            activeStep={activeStep}
            alternativeLabel
            connector={false}
          >
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}>
                <StepLabel StepIconComponent={QontoStepIconMob}>
                  <Box
                    id="current"
                    sx={{
                      background: `${completed[index] ? "#002E5C" : "#2A7BCC"}`,
                      borderRadius: "5px",
                      pt: 0.5,
                      pb: 0.5,
                      // pl:0.5,
                      // pr:0.5,
                      // p:0.5,
                      height:25,
                      textAlign:"center"
                    }}
                  >
                    <Typography
                      id="currentt"
                      sx={{
                        color: "#fff",
                        fontSize: "0.5rem",
                        fontWeight: "bold",
                        textAlign:"center",
                       
                      }}
                    >
                      {label.title}
                    </Typography>
                  </Box>
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          <div>
            {/* {allStepsCompleted() ? (
                <React.Fragment></React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )} */}
          </div>
          <Box sx={{mt:2}}>
                <Typography sx={{fontWeight:"bold",marginBottom:"0px !important"}}>
                Demo Payment Portal: For Simulation Purposes Only - Not Meant for External Use
                </Typography>
              </Box>
        </Box>
        <Box sx={{display: { xs: "block", lg: "none" }}}>
          {/* <Personal/> */}
          {/* <ApplicationFee/> */}
          {/* <Education/> */}
          {/* <Offer/> */}
          {_renderStepContent(activeStep)}
          {/* <AdmissionFee/> */}
          {/* <Test/> */}
        </Box>
      </Box>
    </>
  );
};
export default Basic;
