// import logo from '../images/imi2.png'
const Payment_data=[
{
    id:"imi",
    bg:"../images/bg-imi.webp",
    logo:'../images/imi-new.png',
    registration:[
        {category:10,amount:5000},
       
    ],
    sheetUrl:[
        {category:10,url:"https://script.google.com/macros/s/AKfycbxJ8RjRqoo7GNQXLqqeVHIiKp7vbUzle5TpiQXlJ2rXgbbJTTNyWeYG1d_Cocs0h85H/exec"}
    ],
   SignUpVertical:[
        {id:10,name:"HR Analytics"},
       
    ]
},
{
    id:"spjain",
    logo:'../images/spjain-logo.webp',
    bg:"../images/oscm.webp",
    registration:[
        {category:15,amount:10000},
       
    ],
    // razorpay:"REACT_APP_RAZORPAY_TEST_KEY",
    // https://accredian.com/generate-spjain-applications.php 
    generate_api:"generate-spjain-applications.php",
    admissionPolicy:"https://home.accredian.com/Policy/spjain-admissions-policy",
    confirmationText:"  Your Admissions request has been successfully submitted. You will receive  an Admission confirmation letter within 24 working hours ",
    sheetUrl:[
        {category:15,url:"https://script.google.com/macros/s/AKfycbxJ8RjRqoo7GNQXLqqeVHIiKp7vbUzle5TpiQXlJ2rXgbbJTTNyWeYG1d_Cocs0h85H/exec"}
    ]
    ,
    SignUpVertical:[
        {id:15,name:"Operations & Supply Chain Management"},
       
    ]
},
{
    id:"xlri",
    logo:'../images/Frame 95.png',
    registration:[
        {category:9,amount:10000},
        {category:13,amount:10000},
        {category:14,amount:10000},
    ],
    sheetUrl:[
        {category:9,url:"https://script.google.com/macros/s/AKfycbxwNZ42eYskk9eYIBXeagBI0NUG4N3DnBjyL8OiqLFKIT0UfUv8IusxWAEdvvVmpbyS/exec"},
        {category:13,url:"https://script.google.com/macros/s/AKfycbz0H5zSbqOdTKkxMTxd76VlU2ahBEXufxZV33OEFXX1ibj1GGAIbjSCc_KWrjWx23FlBg/exec"},
        {category:14,url:"https://script.google.com/macros/s/AKfycbwgP4IeWrbw-vHriA14ZrDMQCiQZrm7vi0voLounBWmxt6FjU1yv5a-YftLvyLVNIbaNw/exec"},
    ],
    bg:"../images/bg-xlri.jpg",
    generate_api:"generate-xlri-applications-test.php",
    admissionPolicy:"https://home.accredian.com/Policy/xlri-admissions-policy",
    confirmationText:" Your admission request has been successfully submitted.You'll receive an admission confirmation letter from XLRI within 7 working days.",

    SignUpVertical:[
        {id:9,name:"Product Management"},
        {id:13,name:"General Management"},
        {id:14,name:"Digital Transformation"},
    ]
},
{
    id:"adani",
    logo:'../images/Frame 101.png',
    registration:[
        {category:16,amount:10000},
       
    ],
    sheetUrl:[
        {category:16,url:"jjjjj"},
    ],
    bg:"../images/adani_bg.webp",
    generate_api:"generate-adani-applications.php",
    admissionPolicy:"https://home.accredian.com/Policy/spjain-admissions-policy",
    confirmationText:"  Your Admissions request has been successfully submitted. You will receive  an Admission confirmation letter within 24 working hours ",

    SignUpVertical:[
        {id:16,name:"Executive Program in Cyber Security"},
    ]
}
]
export default Payment_data